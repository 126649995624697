/* global __env */
import { fetchFlagBatch } from '@dashboard-experience/react-flagr';

export const FLAG_KEYS = {
  SIGN_UP_REDESIGN: 'k66dudhzxkdgskfac',
  SIGN_UP_STANDARDIZATION: 'k9tyv1pzw81zk3aui',
  USE_EMBED_FOR_PARTNER_SIGNUP: 'use_embed_for_partner_signup',
  USE_EMBED_FOR_PARTNER_SIGNIN: 'use_embed_for_partner_signin', // https://flagr-ui.checkrhq.net/#/flags/1224
};

export const FLAGR_URL = window?.__env?.REACT_APP_FLAGR_URL;

export const fetchFlagr = async (keys, additionalContext = {}) => {
  const context = {
    env: __env.REACT_APP_ENV,
    ...additionalContext,
  };
  const type = 'account';

  let results;

  try {
    const flags = await fetchFlagBatch(
      keys,
      {
        id: context.account_id,
        type,
        context,
      },
      { url: FLAGR_URL },
    );

    results = {
      flags,
      type,
      context,
    };
  } catch (error) {
    results = {};
  }

  return results;
};

export const loadPagePerfFlag = async () => {
  const key = 'kzcrp4ods7kecnosg';

  const context = {
    id: '123',
    type: 'none',
  };

  const result = await fetchFlagr([key], context);
  return result.flags[key]?.variantAttachment || {};
};

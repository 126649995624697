import React, { useEffect, useMemo } from 'react';
import { toJS } from 'mobx';

const CheckrSignupEmbed = ({ store, signIn }) => {
  const application = toJS(store.application);

  const partner = useMemo(
    () => ({ id: application.client_id, name: application.name }),
    [application.client_id, application.name],
  );
  const env = store.env.REACT_APP_ENV;

  const getSearchParam = key =>
    store.router.location &&
    store.router.location.search &&
    new URLSearchParams(store.router.location.search).get(key);

  // prefer redirect_uri from params
  const paramRedirect = getSearchParam('redirect_uri');

  const oauthRedirectUrl = paramRedirect || application.redirect_uri;

  const oauthRedirectState = getSearchParam('state');

  const paramFirstName = getSearchParam('firstName');
  const paramLastName = getSearchParam('lastName');
  const paramEmail = getSearchParam('email');

  useEffect(() => {
    const embed = new window.Checkr.Embeds.SignUpFlow({
      oauthRedirectUrl,
      oauthRedirectState,
      partner,
      env,
      signIn,
      presetName: paramFirstName,
      presetLastName: paramLastName,
      presetEmail: paramEmail,
    });
    embed.render('#checkr-embed-container');
  }, [
    oauthRedirectUrl,
    oauthRedirectState,
    partner,
    env,
    signIn,
    paramFirstName,
    paramLastName,
    paramEmail,
  ]);

  return (
    <div
      id='checkr-embed-container'
      style={{
        minWidth: '100vw',
        minHeight: '100vh',
      }}
    ></div>
  );
};

export default CheckrSignupEmbed;

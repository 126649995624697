import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FLAG_KEYS } from '../../services/flagr';
import CheckrSignupEmbed from '../../components/views/EmbedSignup/CheckrSignupEmbed';
import ABSwitcher from './ABSwitcher';
import { observer } from 'mobx-react';

const EmbedSwitcher = observer(({ componentName, ...props }) => {
  const { store } = props;
  const flagr = toJS(store.flagr);

  const useSignupEmbed =
    flagr?.flags?.[FLAG_KEYS.USE_EMBED_FOR_PARTNER_SIGNUP]?.variantKey === 'on';

  const useSigninEmbed =
    flagr?.flags?.[FLAG_KEYS.USE_EMBED_FOR_PARTNER_SIGNIN]?.variantKey === 'on';

  const isSignin = componentName === 'signin';

  const shouldShowEmbed = isSignin ? useSigninEmbed : useSignupEmbed;

  return shouldShowEmbed ? (
    <CheckrSignupEmbed signIn={isSignin} {...props} />
  ) : (
    <ABSwitcher componentName={componentName} {...props} />
  );
});

EmbedSwitcher.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default EmbedSwitcher;
